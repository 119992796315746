import contentful from 'clients/contentful'
import { EntryCollection } from 'contentful'
import { ExperimentKit } from '.'
import { ExperimentApiResponse } from './types'

type CacheSettings = {
  staleTTL: number
  cacheKey: string
  backgroundSync: boolean
}

type CacheEntry = {
  data: ExperimentApiResponse
  staleAt: Date
}

const subscribedInstances: Map<string, Set<ExperimentKit>> = new Map()
let cacheInitialized = false
const cache: Map<string, CacheEntry> = new Map()
const activeFetches: Map<string, Promise<EntryCollection<unknown>>> = new Map()

const cacheSettings: CacheSettings = {
  staleTTL: 1000 * 60,
  cacheKey: 'experimentKitCache',
  backgroundSync: true,
}

async function updatePersistentCache() {
  try {
    await globalThis.localStorage.setItem(
      cacheSettings.cacheKey,
      JSON.stringify(Array.from(cache.entries()))
    )
  } catch (e) {
    // Ignore localStorage errors
  }
}

async function setExperimentsOnInstance(
  instance: ExperimentKit,
  data: ExperimentApiResponse
): Promise<void> {
  instance.setExperiments(data.experiments || instance.getExperiments())
}

function onNewExperimentData(key: string, data: ExperimentApiResponse): void {
  const staleAt = new Date(Date.now() + cacheSettings.staleTTL)
  const existing = cache.get(key)
  if (existing) {
    existing.staleAt = staleAt
    return
  }

  // Update in-memory cache
  cache.set(key, {
    data,
    staleAt,
  })
  // Update local storage (don't await this, just update asynchronously)
  updatePersistentCache()

  // Update features for all subscribed ExperimentKit instances
  const instances = subscribedInstances.get(key)
  instances &&
    instances.forEach(instance => setExperimentsOnInstance(instance, data))
}

async function fetchExperiments(
  instance: ExperimentKit
): Promise<ExperimentApiResponse> {
  const { projectId } = instance.getAttributes()

  let promise = activeFetches.get(projectId)
  if (!promise) {
    promise = contentful.getEntries({
      content_type: 'project',
      'sys.id[in]': projectId,
      include: 3,
    })
    activeFetches.set(projectId, promise)
  }

  const data = await promise

  const res: ExperimentApiResponse = data.items.reduce(
    (acc, item) => {
      const fields: any = item.fields
      acc = {
        updatedAt: item.sys.updatedAt,
        experiments: { ...acc.experiments },
      }
      fields.experiments.forEach(e => {
        const key = e?.fields?.key ? e.fields.key : undefined

        if (!key) return

        acc.experiments[key] = {
          id: e.sys.id,
          key,
          name: e.fields.name,
          enabled: e.fields.enabled,
          coverage: e.fields.coverage / 100,
          slug: e.fields?.landingPage?.fields.slug,
          variants: e.fields.variants
            ? e.fields.variants.map(v => {
                return {
                  id: v.sys.id,
                  name: v.fields.name,
                  key: v.fields.trackingKey,
                  split: v.fields.split,
                  weight: v.fields.weight / 100,
                  value: v.fields.text || v.fields.landingPage?.fields.slug,
                  landingPage: v.fields.landingPage,
                }
              })
            : [],
        }
      })
      return acc
    },
    { updatedAt: '', experiments: {} }
  )

  onNewExperimentData(projectId, res)
  activeFetches.delete(projectId)

  return res
}

// Populate cache from localStorage (if available)
async function initializeCache(): Promise<void> {
  if (cacheInitialized) return
  cacheInitialized = true
  if (globalThis.localStorage) {
    try {
      const value = await globalThis.localStorage.getItem(
        cacheSettings.cacheKey
      )
      if (value) {
        const parsed: [string, CacheEntry][] = JSON.parse(value)
        if (parsed && Array.isArray(parsed)) {
          parsed.forEach(([key, data]) => {
            cache.set(key, {
              ...data,
              staleAt: new Date(data.staleAt),
            })
          })
        }
      }
    } catch (e) {
      // Ignore localStorage errors
    }
  }
}

function getKey(instance: ExperimentKit): string {
  const env = instance.getEnv()
  return env
}

async function fetchExperimentsWithCache(
  instance: ExperimentKit,
  allowStale?: boolean,
  timeout?: number,
  skipCache?: boolean
): Promise<ExperimentApiResponse | null> {
  const key = getKey(instance)
  const now = new Date()

  await initializeCache()

  const existing = cache.get(key)
  if (existing && !skipCache && (allowStale || existing.staleAt > now)) {
    if (existing.staleAt < now) {
      fetchExperiments(instance)
    }
  } else {
    const data = await fetchExperiments(instance)
    return data
  }
}

export async function refreshExperiments(
  instance: ExperimentKit,
  timeout?: number,
  skipCache?: boolean,
  allowStale?: boolean,
  updateInstance?: boolean
): Promise<void> {
  const data = await fetchExperimentsWithCache(
    instance,
    allowStale,
    timeout,
    skipCache
  )

  updateInstance && data && (await setExperimentsOnInstance(instance, data))
}

export function subscribe(instance: ExperimentKit): void {
  const key = getKey(instance)
  const subs = subscribedInstances.get(key) || new Set()
  subs.add(instance)
  subscribedInstances.set(key, subs)
}

export function unsubscribe(instance: ExperimentKit): void {
  subscribedInstances.forEach(s => s.delete(instance))
}
