import {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { ExperimentKit } from '.'
import { ExperimentKitSSRData } from './server'
import { ExperimentResult, JSONValue, WidenPrimitives } from './types'

export type ExperimentKitContextValue = {
  experimentKit?: ExperimentKit
}

export const ExperimentKitContext = createContext<ExperimentKitContextValue>({})

function experiment<T extends JSONValue = any>(
  key: string,
  experimentKit: ExperimentKit
): ExperimentResult<T | null> {
  if (!experimentKit) {
    return {
      value: null,
      on: false,
      off: true,
      source: 'unknownExperiment',
    }
  }

  return experimentKit.evalExperiment<T>(key)
}

export function useExperimentKit(data?: ExperimentKitSSRData) {
  const { experimentKit } = useContext(ExperimentKitContext)

  const isFirst = useRef(true)
  if (data && isFirst.current) {
    experimentKit.setAttributes(data.attributes)
    experimentKit.setExperiments(data.experiments)
    experimentKit.setDisabledExperiments(data.disabledExperiments)
    isFirst.current = false
  }

  return experimentKit
}

export function useExperiment<T extends JSONValue = any>(
  key: string
): ExperimentResult<T | null> {
  const ek = useExperimentKit()
  return experiment(key, ek)
}

export function useExperimentValue<T extends JSONValue = any>(
  key: string
): WidenPrimitives<T> {
  const ek = useExperimentKit()
  return ek ? ek.getExperimentValue(key) : null
}

export function useExperimentSplit(key: string): string {
  const ek = useExperimentKit()
  return ek ? ek.split(key) : null
}

export const ExperimentKitProvider: FC<
  PropsWithChildren<{ experimentKit?: ExperimentKit }>
> = ({ children, experimentKit }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setRenderCount] = useState(0)

  useEffect(() => {
    if (!experimentKit || !experimentKit.setRenderer) return

    experimentKit.setRenderer(() => {
      setRenderCount(rc => rc + 1)
    })

    return () => experimentKit.setRenderer(() => {})
  }, [experimentKit])

  return (
    <ExperimentKitContext.Provider value={{ experimentKit }}>
      {children}
    </ExperimentKitContext.Provider>
  )
}
