import { DAY } from 'constants/seconds'
import { parseCookies, setCookie } from 'nookies'
import { ENVIRONMENT_ENDPOINTS } from './constants'
import { refreshExperiments, subscribe, unsubscribe } from './fetch'
import {
  Attributes,
  Context,
  Experiment,
  ExperimentDefinition,
  ExperimentResult,
  ExperimentResultSource,
  JSONValue,
  LoadExperimentsOptions,
  RefreshExperimentsOptions,
  Result,
  SubscriptionFunction,
  Variant,
  WidenPrimitives,
} from './types'
import {
  chooseVariant,
  getBucketRanges,
  getQueryStringOverride,
  hash,
} from './utils'

const isBrowser =
  typeof window !== 'undefined' && typeof document !== 'undefined'

export class ExperimentKit {
  private context: Context
  private _renderer: null | (() => void)
  private _trackedExperiments: Set<unknown>
  private _persistedExperiments: Set<unknown>
  private _subscriptions: Set<SubscriptionFunction>
  private _assigned: Map<
    string,
    {
      experiment: Experiment<any>
      result: Result<any>
    }
  >

  constructor(context?: Context) {
    const { tempId } = parseCookies()
    context = context || {}
    this.context = context

    this._trackedExperiments = new Set()
    this._persistedExperiments = new Set()
    this._subscriptions = new Set()
    this._assigned = new Map()

    const { ekAssigned } = parseCookies()

    if (isBrowser && this.context.devMode) {
      window._experimentKit = this
      document.dispatchEvent(new Event('experimentKitLoaded'))
    }

    if (tempId) {
      this.context.attributes.id = tempId
    }

    if (this.context.attributes?.id) {
      setCookie(null, 'tempId', this.context.attributes.id, {
        path: '/',
        maxAge: DAY,
      })
    }

    if (!this.context.env) {
      this.context.env = 'development'
    }

    if (ekAssigned) {
      this._assigned = new Map(Object.entries(JSON.parse(ekAssigned)))
      this.forceTrack()
    }
  }

  getEnv() {
    return this.context.env
  }

  getUserExperiments() {
    if (!this.context.user?.experiments) {
      return {}
    }
    return this.context.user.experiments.reduce((acc, e) => {
      acc[e.entryId] = {
        ...e,
      }
      return acc
    }, {})
  }

  public async loadExperiments(
    options?: LoadExperimentsOptions
  ): Promise<void> {
    await this._refresh(options, true, true)
    subscribe(this)
  }

  public getExperiments() {
    return this.context.experiments || {}
  }

  public setExperiments(experiments: Record<string, ExperimentDefinition>) {
    this.context.experiments = experiments
    this._render()
  }

  public setAttributes(attributes: Attributes) {
    this.context.attributes = attributes
    this._render()
  }

  public getAttributes() {
    return this.context.attributes
  }

  public setDisabledExperiments(disabledExperiments: string[]) {
    this.context.disabledExperiments = disabledExperiments
  }

  public getDisabledExperiments() {
    return this.context.disabledExperiments
  }

  public setRenderer(renderer: () => void) {
    this._renderer = renderer
  }

  public setAssigned(assigned: any) {
    this.context.assigned = assigned
    this._render()
  }

  public getAssigned() {
    const assigned = Object.fromEntries(this._assigned)
    return assigned
  }

  public getExperimentValue<T extends JSONValue>(
    key: string,
    defaultValue?: T
  ): WidenPrimitives<T> {
    return (
      this.evalExperiment<WidenPrimitives<T>>(key)?.value ??
      (defaultValue as WidenPrimitives<T>)
    )
  }

  public getLandingPageExperiment(slug: string): ExperimentDefinition {
    const key = Object.keys(this.context.experiments).find(
      key => this.context.experiments[key].slug === slug
    )

    return this.context.experiments[key]
  }

  public setUser(user: Record<string, any>) {
    this.context.user = user

    // force tracking and persisting
    this._assigned.forEach((value, key) => {
      this._getExperimentResult(
        key,
        value.result.value,
        'experiment',
        value.experiment,
        value.result
      )
      // this._track(value.experiment, value.result)
    })
  }

  public experiment<T extends JSONValue = any>(
    key: string
  ): ExperimentResult<T | null> {
    return this.evalExperiment(key)
  }

  public split(key: string) {
    return this.evalExperiment(key)?.experimentResult?.split
  }

  public evalExperiment<T extends JSONValue = any>(
    key: string
  ): ExperimentResult<T | null> {
    // disabled experiemnt
    if (this.context.disabledExperiments?.includes(key)) {
      this.log('Forced Disabled experiment', { key })
      return this._getExperimentResult(key, null, 'disabledExperiment')
    }

    // Unknown experiment id
    if (!this.context.experiments || !this.context.experiments[key]) {
      this.log('Unknown experiment', { key })
      return this._getExperimentResult(key, null, 'unknownExperiment')
    }

    const experiment: ExperimentDefinition<T> = this.context.experiments[key]

    if (experiment.coverage) {
      const { hashValue } = this._getHashAttribute()
      if (!hashValue) {
        this.log('Skip rule because of missing hashAttribute', {
          key,
          attributes: this.context.attributes,
        })
      }

      const n = hash(hashValue + key)

      if (n > experiment.coverage) {
        this.log('Skip experiment because of coverage', {
          key,
          experiment,
        })
      }
    }

    if (experiment.variants.length) {
      const res = this._run(experiment as Experiment<T>, key)
      this._fireSubscriptions(experiment as Experiment<T>, res)

      if (res.inExperiment) {
        return this._getExperimentResult(
          key,
          res.value,
          'experiment',
          experiment as Experiment<T>,
          res
        )
      }

      this.log('Use only variant', {
        key,
        value: experiment.variants[0].value ?? null,
      })

      // Fall back to using the default value
      return this._getExperimentResult(
        key,
        experiment.variants[0].value ?? null,
        'defaultVariant',
        experiment as Experiment<T>,
        res
      )
    }

    this.log('Use default value', {
      key,
      value: experiment.enabled,
    })

    // Fall back to using the default value
    return this._getExperimentResult(
      key,
      experiment.enabled as T,
      'defaultValue',
      experiment as Experiment<T>
    )
  }

  public forceTrack() {
    this._assigned.forEach((value, key) => {
      this._track(value.experiment, value.result)
    })
  }

  public run<T>(experiment: Experiment<T>): Result<T> {
    const result = this._run(experiment, null)
    this._fireSubscriptions(experiment, result)
    return result
  }

  private async _refresh(
    options?: RefreshExperimentsOptions,
    allowStale?: boolean,
    updateInstance?: boolean
  ) {
    options = options || {}

    await refreshExperiments(
      this,
      options.timeout,
      options.skipCache || this.context.devMode,
      allowStale,
      updateInstance
    )
  }

  public destroy() {
    // Release references to save memory
    this._subscriptions.clear()
    this._assigned.clear()
    this._trackedExperiments.clear()
    unsubscribe(this)

    if (isBrowser && window._experimentKit === this) {
      delete window._experimentKit
    }
  }

  private _render() {
    if (this._renderer) {
      this._renderer()
    }
  }

  private _run<T>(
    experiment: Experiment<T>,
    experimentKey: string | null
  ): Result<T> {
    const key = experiment.key
    const numVariants = experiment.variants.length
    const userExperiments = this.getUserExperiments()

    const userE = userExperiments[experiment.id]
    const userV = experiment.variants.findIndex(
      v => v.id === userE?.activeVariant.id && v.weight > 0
    )

    // 1. If experiment has less than 2 variations, return
    if (numVariants < 2) {
      this.log('Invalid experiment', { id: key })
      return this._getResult(experiment, -1, false, experimentKey)
    }

    // 2. If the context is disabled, return immediately
    if (this.context.enabled === false) {
      this.log('Context disabled', { id: key })
      return this._getResult(experiment, -1, false, experimentKey)
    }

    // 3. If the context is disabled, return immediately
    if (experiment.enabled === false) {
      this.log('Experiment disabled', { id: key })
      return this._getResult(experiment, -1, false, experimentKey)
    }

    // 4. If a variation is forced from a querystring, return the forced variation
    const qsOverride = getQueryStringOverride(
      key,
      this._getContextUrl(),
      experiment.variants
    )
    if (qsOverride !== null) {
      this.log('Force via querystring', {
        id: key,
        variant: qsOverride,
      })
      const result = this._getResult(
        experiment,
        qsOverride,
        false,
        experimentKey
      )
      // Fire the tracking callback
      this._track(experiment, result)
      return result
    }

    // 5. If user is already in experiment use their assignment
    if (userV > -1) {
      // this.log('User already assigned to experiment', userE)
      this.log('User already assigned to experiment')
      const result = this._getResult(experiment, userV, false, experimentKey)
      // Fire the tracking callback
      this._track(experiment, result)
      return result
    }

    // 6. Get the hash attribute and return if empty
    const { hashValue } = this._getHashAttribute(experiment.hashAttribute)
    if (!hashValue) {
      this.log('Skip because missing hashAttribute', {
        id: key,
      })
      const result = this._getResult(experiment, -1, false, experimentKey)
      // Fire the tracking callback
      this._track(experiment, result)
      return result
    }

    // 7. Get bucket ranges and choose variation
    const ranges = getBucketRanges(
      numVariants,
      experiment.coverage ?? 1,
      experiment.variants.map((v: Variant<T>) => v.weight)
    )
    const n = hash(hashValue + key)
    const assigned = chooseVariant(n, ranges)

    // 8. Return if not in experiment
    if (assigned < 0) {
      this.log('Skip because of coverage', {
        id: key,
      })
      const result = this._getResult(experiment, -1, false, experimentKey)
      // Fire the tracking callback
      this._track(experiment, result)
      return result
    }

    // 9. Build the result object
    const result = this._getResult(experiment, assigned, true, experimentKey)

    // 10. Fire the tracking callback
    this._track(experiment, result)

    // 11. Return the result
    this.log('In experiment', {
      id: key,
      split: result.split,
    })
    return result
  }

  private _getResult<T>(
    experiment: Experiment<T>,
    variantIndex: number,
    hashUsed: boolean,
    experimentKey: string | null
  ): Result<T> {
    let inExperiment = true

    // If assigned variation is not valid, use the baseline and mark the user as not in the experiment
    if (variantIndex < 0 || variantIndex >= experiment.variants.length) {
      variantIndex = 0
      inExperiment = false
    }

    const { hashAttribute, hashValue } = this._getHashAttribute(
      experiment.hashAttribute
    )

    return {
      experimentKey,
      inExperiment,
      hashUsed,
      variantIndex,
      variantId: experiment.variants[variantIndex].id,
      split: experiment.variants[variantIndex].split,
      value: experiment.variants[variantIndex].value,
      landingPage: experiment.variants[variantIndex].landingPage,
      hashAttribute,
      hashValue,
    }
  }

  private _getHashAttribute(attr?: string) {
    const hashAttribute = attr || 'id'

    let hashValue = ''
    // if (this._attributeOverrides[hashAttribute]) {
    //   hashValue = this._attributeOverrides[hashAttribute]
    if (this.context.attributes && this.context.attributes[hashAttribute]) {
      hashValue = this.context.attributes[hashAttribute] || ''
    } else if (this.context.user) {
      hashValue = this.context.user[hashAttribute] || ''
    }

    return { hashAttribute, hashValue }
  }

  private _getContextUrl() {
    return (
      this.context.url ||
      this.context.attributes.url ||
      (isBrowser ? window.location.href : '')
    )
  }

  private _getExperimentResult<T>(
    key: string,
    value: T,
    source: ExperimentResultSource,
    experiment?: Experiment<T>,
    result?: Result<T>
  ): ExperimentResult<T> {
    const ret: ExperimentResult = {
      value,
      on: !!value,
      off: !value,
      split: result?.split,
      source,
    }
    if (experiment) ret.experiment = experiment
    if (result) ret.experimentResult = result

    // Track the usage of this feature in real-time
    this._persist(ret)

    return ret
  }

  private async _persist(res: ExperimentResult): Promise<void> {
    const { 'session-token': sessionToken } = parseCookies()
    // Don't track usage for override or coverage or no user or already persisted
    if (
      res.source === 'override' ||
      res.source === 'defaultValue' ||
      res.source === 'unknownExperiment' ||
      res.source === 'disabledExperiment' ||
      !this.context.user ||
      !sessionToken ||
      this._persistedExperiments.has(res.experiment.id)
    ) {
      this.log('Save skipped')
      return
    }

    const variables = {
      experiment: {
        id: res.experiment.id,
        activeVariant: {
          id: res.experiment.variants[res.experimentResult.variantIndex].id,
          name: res.experiment.variants[res.experimentResult.variantIndex].name,
        },
      },
    }

    const fetchRes = await fetch(ENVIRONMENT_ENDPOINTS[process.env.APP_ENV], {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${sessionToken}`,
      },
      body: JSON.stringify({
        query: `
          mutation addUserExperiment($experiment: ExperimentPayload!) {
            addUserExperiment(experiment: $experiment)
          }
        `,
        variables,
      }),
    })

    if (fetchRes.ok) {
      this._persistedExperiments.add(res.experiment.id)
    } else {
      this.log('track usage save error', {
        status: fetchRes.status,
        statusText: fetchRes.statusText,
      })
    }
  }

  private _fireSubscriptions<T>(experiment: Experiment<T>, result: Result<T>) {
    const key = experiment.key

    // If assigned variation has changed, fire subscriptions
    const prev = this._assigned.get(key)
    // TODO: what if the experiment definition has changed?
    if (
      !prev ||
      prev.result.inExperiment !== result.inExperiment ||
      prev.result.variantIndex !== result.variantIndex
    ) {
      this._assigned.set(key, { experiment, result })
      this._subscriptions.forEach(cb => {
        try {
          cb(experiment, result)
        } catch (e) {
          console.error(e)
        }
      })
    }
  }

  private _track<T>(experiment: Experiment<T>, result: Result<T>) {
    const { ekAssigned } = parseCookies()

    if (!this.context.tracking || !isBrowser) {
      let assigned = {}
      if (ekAssigned) {
        assigned = JSON.parse(ekAssigned)
      }
      assigned[experiment.key] = { experiment, result }

      setCookie(null, 'ekAssigned', JSON.stringify(assigned))
      return
    }

    const key = experiment.key

    // Make sure a tracking callback is only fired once per unique experiment
    const k =
      result.hashAttribute + result.hashValue + key + result.variantIndex
    if (this._trackedExperiments.has(k)) return
    this._trackedExperiments.add(k)

    try {
      this.context.tracking(experiment, result)
    } catch (e) {
      console.log('tracking error')
      console.error(e)
    }
  }

  log(msg: string, ctx?: Record<string, unknown>) {
    if (!this.context.devMode) return
    if (this.context.log) this.context.log(msg, ctx)
    else console.log(msg, ctx || '')
  }
}
