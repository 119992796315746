import { useRouter } from 'next/router'
import { useEffect } from 'react'
import GoogleTagManager from '../containers/google-tag-manager'
import NProgress from 'nprogress'
import uuid from 'uuid/v4'
import { PAGES } from 'constants/client-dashboard'
import { ExperimentKit } from '../utilities/experiment'
import { ExperimentKitProvider } from '../utilities/experiment/context'
import { AnalyticsManager } from 'utilities'
import PROJECTS from '../utilities/experiment/constants'

const ek = new ExperimentKit({
  attributes: { projectId: PROJECTS.WEB, id: uuid() },
  tracking: (experiment, result) => {
    AnalyticsManager.setUserProperties({
      [result.experimentKey]: result.split,
    })
  },
  devMode: process.env.NODE_ENV === 'development',
})

const CLIENT_DASHBOARD_REGEX = new RegExp(
  `/dashboard/(${Object.values(PAGES).join('|')})`
)

function MyApp({ Component, pageProps }) {
  const router = useRouter()

  useEffect(() => {
    const handleStart = url => {
      if (CLIENT_DASHBOARD_REGEX.test(url || '')) {
        NProgress.start()
      }
    }

    const handleStop = () => {
      NProgress.done()
    }

    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [router.events])

  useEffect(() => {
    ek.loadExperiments()
  }, [])

  return (
    <GoogleTagManager>
      <ExperimentKitProvider experimentKit={ek}>
        <Component {...pageProps} />
      </ExperimentKitProvider>
      <style jsx global>{`
        /* Make clicks pass-through */
        #nprogress {
          pointer-events: none;
        }

        #nprogress .bar {
          background: #830051;

          position: fixed;
          z-index: 1031;
          top: 0;
          left: 0;

          width: 100%;
          height: 4px;
        }

        /* Fancy blur effect */
        #nprogress .peg {
          display: block;
          position: absolute;
          right: 0px;
          width: 100px;
          height: 100%;
          box-shadow: 0 0 10px #830051, 0 0 5px #830051;
          opacity: 1;

          -webkit-transform: rotate(3deg) translate(0px, -4px);
          -ms-transform: rotate(3deg) translate(0px, -4px);
          transform: rotate(3deg) translate(0px, -4px);
        }
      `}</style>
    </GoogleTagManager>
  )
}

export default MyApp
