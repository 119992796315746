const isBrowser =
  typeof window !== 'undefined' && typeof document !== 'undefined'

const amplitude = isBrowser ? require('@amplitude/analytics-browser') : null

/*
 ** Analytics Manager singleton
 */
class AnalyticsManager {
  private _instance
  private _identify

  constructor() {
    if (!this._instance) {
      this._instance = this
      if (isBrowser) {
        amplitude.init(process.env.AMPLITUDE_SDK_API_KEY, null, {
          // logLevel: amplitude.Types.LogLevel.Debug,
        })
        this._identify = new amplitude.Identify()
      }
    }

    return this._instance
  }

  logEvent(name, properties) {
    amplitude.track(name, properties || null)
  }

  identifyUser({ me }) {
    if (!me) return

    if (me.user) {
      const {
        user: { id, email },
      } = me

      if (me.user.coach) {
        const coach = me.user.coach
        this._identify.set('coachId', coach.id)
        this._identify.set('coachLevel', coach.levelId)
      }

      amplitude.setUserId(id)
      this._identify.set('email', email)
      amplitude.identify(this._identify)
    }
  }

  setUserProperties(properties) {
    Object.keys(properties).forEach(key => {
      this._identify.set(key, properties[key])
    })
    amplitude.identify(this._identify)
  }

  setTotalActiveClients(totalActiveClients) {
    this._identify.set('Total Active Clients', totalActiveClients)
    amplitude.identify(this._identify)
  }

  setDeviceId(deviceId) {
    amplitude.setDeviceId(deviceId)
  }
}

const instance = new AnalyticsManager()
Object.freeze(instance)

export default instance
