import * as R from 'ramda'

export const PAGES = {
  TODOS: 'todos',
  CLIENTS: 'client-list',
  INBOX: 'inbox',
  EARNINGS: 'earnings',

  // Recruiter based routes
  RECRUITER: 'recruiter',
  RECRUITER_FREE_TRIAL: 'recruiter-free-trial',
  RECRUITER_ADVERTISE_TRAINING: 'recruiter-advertise-training',
}

export const PAGES_SET = new Set(R.values(PAGES))
